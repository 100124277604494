import { Link } from 'react-router-dom';

import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import { Offer } from '@/fsd/pages/Cart/utils/Offer';
import { TypeOffer, TypeProduct } from '@/fsd/shared/types';
import { getOfferByProdIdInCart } from '@/fsd/shared/ui/Cart/cartUtils';
import { RoutesNames } from '@/routes/RoutesName';

import s from './CartItemTitle.module.scss';

export const CartItemTitle = ({
  product,
  cartItem,
}: {
  product: TypeProduct;
  cartItem: CartItemType;
}) => {
  const offer = getOfferByProdIdInCart(product, cartItem);
  // const color = product?.danceex_options.color || '';

  return (
    <div
      className={s['CartItem-Title']}
      style={{ display: 'flex', gap: '4px', flexDirection: 'column' }}
    >
      <Link to={RoutesNames.ProductPage + `${product.id}`}>
        <p className={s['ProductCard-Title']}>{product.name}</p>
      </Link>
      {offer && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Offer.PropertiesList offer={offer} />
        </div>
      )}
      {/* {color && (
        <h4 className='FakeSwatches-Title'>
          Колір <span>{color}</span>
        </h4>
      )} */}
      {offer && <Price offer={offer} />}
    </div>
  );
};

const Price = ({ offer }: { offer: TypeOffer }) => {
  const isDiscount = false;

  return (
    <div className={s['CartItem-Title']}>
      <p aria-label='Ціна товару' className='ProductPrice ProductPrice_sale'>
        <ins aria-label='Поточна ціна товару'>
          <data value={offer.price}>{offer.price}&nbsp;грн</data>
        </ins>
        {isDiscount && <DiscountDetails offer={offer} />}
      </p>
    </div>
  );
};

const DiscountDetails = ({ offer }: { offer: TypeOffer }) => {
  const percent = 70;
  const oldPrice = (offer.price + (+offer?.price * percent) / 100).toFixed(0);

  return (
    <>
      <del aria-label='Попередня ціна товару'>{oldPrice}</del>
      <span className='discountPercentage'>{percent}% off</span>
    </>
  );
};
