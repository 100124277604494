import { useEffect, useState } from 'react';

import { StoredUserData, UserBuyerI, UserDataI } from '@/fsd/shared/utils/StoredUserData';
import { TextField } from '@mui/material';

import './UserInputFieldsComponents.scss';

export function UserInputFieldsComponents() {
  // const cartProducts = useAppSelector(cartProductsSelectors.products);
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [buyer_comment, setBuyerComment] = useState<string>('');
  const [isAllowSaveUser, setIsAllowSaveUser] = useState<boolean>(false);

  const [fullNameError, setFullNameError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);

  useEffect(() => {
    const user = StoredUserData.getUser();
    setFullName(user.buyer.full_name);
    setPhone(user.buyer.phone);
    setEmail(user.buyer.email);
    setBuyerComment(user.buyer_comment);    
  }, []);

  useEffect(() => {
    if (isAllowSaveUser) {
      if (fullName.trim().length < 1) {
        setFullNameError(true)
      } else {
        setFullNameError(false)
      }
      if (phone.trim().length < 9) {
        setPhoneError(true)
      } else {
        setPhoneError(false)
      }
    }
    // Unblock Continune buttons
    // if (fullName.trim().length > 0 && email.trim().length > 0 && phone.trim().length > 0) {
    //   setShowUserInputYourData(false);
    // } else {
    //   setShowUserInputYourData(true);
    // }
    // Unblock Continue buttons

    saveUser()
  }, [fullName, email, phone]);


  const saveUser = () => {
    if (isAllowSaveUser) {
      const userData: Partial<UserDataI> = {}

      const userBuyer: UserBuyerI = {
        full_name: fullName,
        phone: phone,
        email: email,
      }
      userData.buyer = userBuyer;

      if (buyer_comment.trim().length > 0) {
        userData.buyer_comment = buyer_comment
      }

      StoredUserData.saveUser({ userData })
    }
  };

  return (
    <div style={{ paddingTop: '40px' }}>
      <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
        <TextField
          required
          error={fullNameError}
          type="text"
          label="Ім'я"
          variant='outlined'
          size='small'
          onChange={(e) => {
            setFullName(e.target.value);
            setIsAllowSaveUser(true)
            saveUser();
          }}
          value={fullName}
        />
        <TextField
          required
          error={phoneError}
          type='tel'
          label='Телефон'
          variant='outlined'
          size='small'
          onChange={(e) => {
            setPhone(e.target.value.trim());
            setIsAllowSaveUser(true)
            saveUser();
          }}
          value={phone}
        />
        <TextField
          type='email'
          label='email'
          variant='outlined'
          size='small'
          onChange={(e) => {
            setEmail(e.target.value.trim());
            setIsAllowSaveUser(true)
            saveUser();
          }}
          value={email}
        />
        <TextField
          label='Коментар'
          variant='outlined'
          size='small'
          onChange={(e) => {
            setBuyerComment(e.target.value);
            setIsAllowSaveUser(true)
            saveUser();
          }}
          value={buyer_comment}
          multiline
          rows={3}
        // maxRows={4}
        />
      </div>
    </div>
  );
}