import { useAppSelectorWithTypes } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { useTitle } from '@/hooks';
import { View } from './comps/View';

import { CartIsEmpty } from '../utils/CartIsEmpty';

export const CheckoutPage = () => {
  useTitle('Оформлення замовлення');
  const cartRaw = useAppSelectorWithTypes(getCart);

  if (cartRaw.length === 0) {
    return <CartIsEmpty />;
  }

  return <View />;
};
