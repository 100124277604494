import { useAppDispatch, useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions, getCart } from '@/fsd/entities/cart';
import { cartProductsSelectors } from '@/fsd/entities/cartProducts/model/selectors/cartProductsSelectors';
import { getProductByProdIdInCart } from '@/fsd/shared/ui/Cart/cartUtils';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { IconButton } from '@mui/material';
import { CartItem } from '../widgets/CartIem';

import s from './CartPageMainWrapper.module.scss';

const useStyle = {
  Button: {
    '&:hover': {
      boxShadow: 'lightcoral !important',
      color: 'red',
    },
    '&:active': {
      boxShadow: 'none !important',
      backgroundColor: 'lightcoral !important',
      color: 'unset',
    },
  },
};

export const CartPageMainWrapper = () => {
  const cartRaw = useAppSelector(getCart);
  const cartProducts = useAppSelector(cartProductsSelectors.products);
    const dispatch = useAppDispatch();

  cartRaw.map((cartItem) => {
    getProductByProdIdInCart(cartProducts, cartItem);
  });

    const emptyCart = () => {
      dispatch(cartSliceActions.emptyCart());
    };

  return (
    <div className={s['CartPage-MainWrapper']}>
      <div
        aria-label='Перелік товарів у кошику'
        className={s['CartPage-ItemsList'] + ' ' + 'border-wrapper'}
      >
        <div className={s['header']}>
          <h3>Усі товари ({cartRaw.length})</h3>
          <IconButton
            aria-label='Remove Shopping Cart'
            onClick={emptyCart}
            sx={useStyle.Button}
          >
            <RemoveShoppingCartIcon />
          </IconButton>
        </div>

        <ul>
          {cartRaw.map(function (cartItem, idx) {
            // const product = getProductByProdIdInCart(cartProducts, cartProd);
            // dispatch(cartProductsSliceActions.setCurrentProduct(product));

            return <CartItem key={idx} cartItem={cartItem} />;
          })}
        </ul>
      </div>
    </div>
  );
};
