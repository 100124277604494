import { useTitle } from '@/hooks';
import { CollapsedProducts } from './CollapsedProducts';
import { CreateOrderBtn } from './CreateOrderBtn';

import { UserInputFieldsComponents } from '../../utils/UserInputFieldsComponents';

import cls from './View.module.scss';

export function View() {
  useTitle('Оформлення замовлення');

  return (
    <div className='ContentWrapper ContentWrapper-Vertical'>
      <h1 style={{ textAlign: 'center' }}>Оформлення замовлення</h1>
      <div className={cls.Container}>
        <UserInputFieldsComponents />
        <CollapsedProducts />
        {/* <Payment />
        <Delivery /> */}
        <CreateOrderBtn />
      </div>
    </div>
  );
}

