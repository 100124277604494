import CookieConsent from "react-cookie-consent";
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { MAX_WIDTH_TABLET } from '@/const';
import { Footer, Header } from '@/fsd/shared/ui';
import { Divider, useMediaQuery } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const isTablet = useMediaQuery(MAX_WIDTH_TABLET);

  return (
    <>
      <Header />
      {!isTablet && <div style={{ paddingBottom: '0.5rem' }}></div>}
      {/* <DD />
      <div className='ContentWrapper '>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='hover' color='inherit' href='/'>
            MUI
          </Link>
          <Link
            underline='hover'
            color='inherit'
            href='/material-ui/getting-started/installation/'
          >
            Core
          </Link>
          <Typography sx={{ color: 'text.primary' }}>Breadcrumbs</Typography>
        </Breadcrumbs>
      </div> */}
      <Outlet />
      <Divider />
      <Footer />
      <ToastContainer />
      <CookieConsent
        location="bottom"
        buttonText="Згоден"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", marginBottom: 80 }}
        // style={{ background: "#2B373B", marginBottom: 20, maxWidth: 'calc(100% - 150px)' }}
        buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
        expires={150}
      >
        Цей веб-сайт використовує файли cookie для покращення взаємодії з користувачем.
      </CookieConsent>
    </>
  );
}

export default App;
