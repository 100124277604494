import { useAppSelectorWithTypes } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { useTitle } from '@/hooks';
import { WrapAutoOpenMono } from './comps/WrapAutoOpenMono';
import { CartIsEmpty } from '../utils/CartIsEmpty';

export const CheckoutPageAutoOpenMono = () => {
  useTitle('Оформлення замовлення');
  const cartRaw = useAppSelectorWithTypes(getCart);

  if (cartRaw.length === 0) {
    return <CartIsEmpty />;
  }

  return <WrapAutoOpenMono />;
};
