
export const CartIsEmpty = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        gap: 16,
        paddingBottom: 24,
      }}
    >
      <h2>Корзина порожня.</h2>
      <h3>Для оформення замовлення, будь ласка, додайте товари в коризину</h3>
    </div>
  );
};