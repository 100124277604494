import { useAppSelectorWithTypes } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { useGetProductsByIdsCartQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { EmptyCart } from '@/fsd/shared/ui/Cart/EmptyCart';
import { useTitle } from '@/hooks';
import { CustomCartView } from '../widgets/CustomCartView';

export const CartPageCustom = () => {
  useTitle('Корзина');
  const cartRaw = useAppSelectorWithTypes(getCart);

  if (cartRaw.length === 0) {
    return <EmptyCart />;
  }

  return <CheckRequirements />;
};

function CheckRequirements() {
  const cartRaw = useAppSelectorWithTypes(getCart);

  const ids = cartRaw.map((_) => _.item.product_id);
  const listIds = ids.join(', ');

  const { currentData, isLoading, error } = useGetProductsByIdsCartQuery(ids);

  if (isLoading) {
    return (
      <div
        className='ContentWrapper ContentWrapper-Vertical'
        style={{ fontSize: '36px' }}
      >
        Завантаження ids {listIds}
      </div>
    );
  }

  if (error) {
    return (
      <div
        className='ContentWrapper ContentWrapper-Vertical'
        style={{ fontSize: '36px', color: 'red' }}
      >
        Помилка завантаження ids {listIds}
      </div>
    );
  }

  if (!currentData || currentData.data.length === 0) {
    return (
      <div
        className='ContentWrapper ContentWrapper-Vertical'
        style={{ fontSize: '36px', color: 'red' }}
      >
        Товари ids {listIds} не знайдені
      </div>
    );
  }

  return <CustomCartView />;
}
