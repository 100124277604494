import { Left } from './features/Left/Left';
import { Right } from './features/Right/Right';
import { useTitle } from '@/hooks';

import './ContactsPage.css';

export const ContactsPageWrapper = () => {
  useTitle('Контакти');

  return (
    <div className='ContentWrapper ContentWrapper-Vertical'>
      <div className='contact-locations'>
        <Left />
        <Right />
      </div>
    </div>
  );
};

export const ContactsPageView = () => {
  return (
      <div className='contact-locations'>
        <Left />
        <Right />
      </div>
  );
};
