import { Link } from 'react-router-dom';

import { TypeProductComponent } from '@/fsd/shared/types';
import { TheSameGood } from '@/fsd/shared/ui/commons/TheSameGood';
import { RoutesNames } from '@/routes/RoutesName';

export const CartItemThumbnail = ({ product }: TypeProductComponent) => {
  return (
    <div>
      <Link to={RoutesNames.ProductPage + `${product.id}`} className='flex'>
        <TheSameGood product={product} />
        {/* {currProd && <TheSameGood product={currProd} />} */}
      </Link>
    </div>
  );
};
