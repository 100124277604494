import { RootState } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/config/store';

/**
 // cartRaw example
 
  [
      {
          "qty": 1,
          "item": {
              "product_id": 2,
              "offer_id": 3,
              "color": "Беж"
          }
      },
      {
          "qty": 1,
          "item": {
              "product_id": 254,
              "offer_id": 1151,
              "color": null
          }
      },
      {
          "qty": 1,
          "item": {
              "product_id": 700,
              "offer_id": 3080,
              "color": "Зелений"
          }
      }
  ]
*/
export const getCart = (state: RootState) => state.cartSliceReducer.cart;
