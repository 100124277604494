import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

import { useKeyCrmCreateOrderMutation } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQKeyCrmApi';
import { useLazyBuildKeyCrmCartAutoOpenMonoCheckout } from '@/fsd/shared/ui/Cart/useLazyBuildKeyCrmCartAutoOpenMonoCheckout';
import { RoutesNames } from '@/routes/RoutesName';
import { Button, CircularProgress, Typography } from '@mui/material';

/**
 * Send Cart To KeyCrm And Auto Open MonoCheckout
 * @returns 
 */
export function SendCartToKeyCrmAndAutoOpenMonoCheckout() {
  const [isTryCreateKeyCrmOrder, setTryCreateKeyCrmOrder] = useState<boolean | undefined>(false);
  const [msgNotConnctedToServer, setMsgNotConnctedToServer] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const [keyCrmCreateOrder] = useKeyCrmCreateOrderMutation();

  const {
    requestProductAndGetBuildedCart,
    buildedCart,
    isLoadingProducts
  } = useLazyBuildKeyCrmCartAutoOpenMonoCheckout({
    fullName: 'Danceex покупець',
    phone: '+380501000000',
    email: 'danceex_buyer@gmail.com',
    buyer_comment: 'Замовлення перенапралено на Моно оплату',
  })

  useEffect(() => {
    requestProductAndGetBuildedCart()
  }, [])

  useEffect(() => {
    if (buildedCart) {
      createKeyCrmOrderOnServer()
    }
  }, [buildedCart])

  const createKeyCrmOrderOnServer = () => {

    // Fix create 2 orders
    if (isTryCreateKeyCrmOrder === false) {
      setTryCreateKeyCrmOrder(true);
      setMsgNotConnctedToServer(undefined);

      keyCrmCreateOrder({ keyCrmCartObject: buildedCart })
        .then((res) => res.data)
        .then((_keyCrmResponse) => {
          if (_keyCrmResponse !== undefined) {
            navigate(RoutesNames.OrderGroup.OrderAutoOpenMonoPlata + '/' + _keyCrmResponse.id, {
              // replace: true,
              state: { keyCrmOrderInfo: _keyCrmResponse },
            });
          } else {
            const msg = 'Не вдалось надіслати замовлення. Перевірте інтернет та спробуйте знову. Або будь ласка напишіть повідомлення у чат менеджеру'
            toast.info(msg, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              transition: Bounce,
            });
          }
        })
        .catch((_) => console.error(_))
        .finally(() => setTryCreateKeyCrmOrder(false))
    }
  };

  const Loaders = () => (
    <>
      {isLoadingProducts && <div>Завантажаження списку товарів <CircularProgress color='success' /></div>}
      {isTryCreateKeyCrmOrder && <div>Створення замовлення на сервері <CircularProgress color='info' /></div>}
    </>
  )

  const ContinueButton = () => (
    <Button
      variant='outlined'
      onClick={createKeyCrmOrderOnServer}
      style={{ marginTop: '12px' }}
    >
      Повторити спробу створення замовлення
    </Button>
  )

  return (
    <div style={{ paddingTop: '40px' }}>
      {msgNotConnctedToServer && <Typography style={{ marginTop: '24px' }}>{msgNotConnctedToServer}</Typography>}
      <Loaders />
      <ContinueButton />
    </div>
  );

}
