import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import { TypeProduct } from '@/fsd/shared/types';
import { Add } from '@/fsd/shared/ui/Cart/actions/Add';
import { Reduce } from '@/fsd/shared/ui/Cart/actions/Reduce';

import { CartItemButtonDelete } from '../widgets/CartItemDeleteButton/CartItemButtonDelete';
import { CartItemSum } from '../widgets/CartItemSum';
import { CartItemTitle } from '../widgets/CartItemTitle';
import s from './CartItemInfo.module.scss';

export const CartItemInfo = ({
  product,
  cartItem,
}: {
  product: TypeProduct;
  cartItem: CartItemType;
}) => {
  return (
    <div
      aria-label='Інформація про кошик'
      className={s['CartItem-Info']}
      debug-data-product-id={cartItem.item.product_id}
      debug-data-offer-id={cartItem.item.offer_id}
    >
      <CartItemTitle product={product} cartItem={cartItem} />
      <CartItemSum product={product} cartItem={cartItem} />

      <div className={s['Buttons-Add-Remove']}>
        <Reduce cartItem={cartItem} />
        {cartItem.qty}
        <Add cartItem={cartItem} />
      </div>
      {/* <CartItemButtons cartItem={cartItem} /> */}
      <CartItemButtonDelete cartItem={cartItem} />
    </div>
  );
};
