import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { __IS_DEV__ } from '@/const.ts';
import { cartSliceReducer } from '@/fsd/entities/cart';
import { cartProductsSliceReducer } from '@/fsd/entities/cartProducts';
import { mainMenuReducer } from '@/fsd/entities/MainMenu/model/slices/mainMenuSlice';
import { productsFiltersReducer } from '@/fsd/entities/ProductFilters';
import { RTKQKeyCrmApi } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQKeyCrmApi';
import { RTKQLiqPayCheckout } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQLiqPayCheckout';
import { RTKQMonobankCheckout } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQMonobankCheckout';
import { RTKQProductsListApi } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi.ts';
import { RTKQAdminApi } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApiAdmin';
import { selectedOfferReducer } from '@/fsd/entities/SelectedOffer';
import { selectedProductReducer } from '@/fsd/entities/SelectedProduct';
import { selectedProductGroupReducer } from '@/fsd/entities/SelectedProductGroup';
import { settingsReducer } from '@/fsd/entities/Settings/model/slices/settingsSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  settingsReducer,
  mainMenuReducer,
  productsFiltersReducer,
  selectedProductReducer,
  selectedProductGroupReducer,
  selectedOfferReducer,
  cartSliceReducer,
  cartProductsSliceReducer,
  [RTKQProductsListApi.reducerPath]: RTKQProductsListApi.reducer,
  [RTKQAdminApi.reducerPath]: RTKQAdminApi.reducer,
  [RTKQMonobankCheckout.reducerPath]: RTKQMonobankCheckout.reducer,
  [RTKQLiqPayCheckout.reducerPath]: RTKQLiqPayCheckout.reducer,
  [RTKQKeyCrmApi.reducerPath]: RTKQKeyCrmApi.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'settingsReducer',
    'mainMenuReducer',
    'productsFiltersReducer',
    'selectedProductReducer',
    'selectedProductGroupReducer',
    'selectedOfferReducer',
    RTKQProductsListApi.reducerPath,
    RTKQAdminApi.reducerPath,
    RTKQMonobankCheckout.reducerPath,
    RTKQLiqPayCheckout.reducerPath,
    RTKQKeyCrmApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: __IS_DEV__,
  // preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      // .concat([RTKQProductsListApi.middleware, RTKQAdminApi.middleware])
      .concat(RTKQProductsListApi.middleware)
      .concat(RTKQAdminApi.middleware)
      .concat(RTKQMonobankCheckout.middleware)
      .concat(RTKQLiqPayCheckout.middleware)
      .concat(RTKQKeyCrmApi.middleware),
});

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
