import { TypeProduct } from 'src/fsd/shared/types';

export function fromJSON(data: any) {
  if (data === undefined) {
    console.log('ProductsGrid: data is undefuned');
    return [];
  }

  if (!Array.isArray(data)) {
    console.log('ProductsGrid: data is not array');
    return [];
  }

  const listData = data.map((_) => {
    const prod: TypeProduct = {
      id: _.id,
      name: _.name,
      // description: _.description,
      thumbnail_url: _.thumbnail_url,
      attachments_data: _.attachments_data,
      quantity: _.quantity,
      // unit_type: _.unit_type,
      // in_reserve: _.in_reserve,
      currency_code: _.currency_code,
      sku: _.sku,
      min_price: _.min_price,
      max_price: _.max_price,
      danceex_options: _.danceex_options,
      in_reserve: _.in_reserve,
      properties_agg: _.properties_agg,
      description: null,
      has_offers: _.has_offers,
      overwrite_keycrm: _?.overwrite_keycrm || { thumbnail_url: '', attachments_data: [] },
    };
    return prod;
  });

  return listData;
}
