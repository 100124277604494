import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import { TypeProduct } from '@/fsd/shared/types';

import s from './CartItemSum.module.scss';

export const CartItemSum = ({
  product,
  cartItem,
}: {
  product: TypeProduct;
  cartItem: CartItemType;
}) => {
  const _price = cartItem.qty * product.min_price;

  return (
    <div className={s['CartItem-Price']}>
      <h4>Підсумок: </h4>
      <p
        aria-label='Ціна товару'
        className='ProductPrice ProductPrice_type_regular'
      >
        <ins aria-label='Поточна ціна товару'>
          <data value={_price}>{_price}&nbsp;грн</data>
        </ins>
        {/* <del aria-label='Попередня ціна товару'>(11040)</del>
        <span className='discountPercentage'>70% off</span> */}
      </p>
    </div>
  );
};
