
export function LiqPayCheckoutCallback({ liqpay_form_raw, liqpayCallback }: { liqpay_form_raw: any, liqpayCallback?: (data: any) => void }) {
  window.LiqPayCheckout.init({
    data: liqpay_form_raw.data,
    signature: liqpay_form_raw.signature,
    embedTo: liqpay_form_raw.embedTo,
    language: liqpay_form_raw.language,
    mode: liqpay_form_raw.mode,
  }).on("liqpay.callback", function (data: any) {
    console.log(data.status);
    console.log(data);
    liqpayCallback && liqpayCallback(data)
  }).on("liqpay.ready", function (data: any) {
    console.log(data);
  }).on("liqpay.close", function (data: any) {
    console.log(data);
  });
};