import { useState } from 'react';

import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { useLazyGetProductsByIdsQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { getOfferByProdIdInCart, getProductByProdIdInCart } from './cartUtils';
import { TypeOffer, TypeProduct } from '../../types';

interface T {
  fullName: string,
  phone: string,
  email: string,
  buyer_comment: string,
}

/**
 * 
 //  Example buildedCart

  {
    "order": {
      "source_id": 5,
      "source_name": "danceex.com.ua",
      "products": [
          {
            "sku": "00003-w-23",
            "price": 199,
            "quantity": 1,
            "comment": "Балетки білі на резинці: Розмір взуття 23"
          },
          {
            "sku": null,
            "price": 1600,
            "quantity": 1,
            "comment": "Відсутній артикул / Бейсік 1 синій Galex: "
          },
      ],
      "buyer": {
        "full_name": "Danceex покупець",
        "phone": "+380501000000",
        "email": "danceex_buyer@gmail.com"
      },
      "buyer_comment": "Замовлення перенапралено на Моно оплату"
    }
  }
 */
export const useLazyBuildKeyWithNewCredentials = () => {
  const [buildedCart, setBuildedCart] = useState<any>(undefined);

  const cartRaw = useAppSelector(getCart);
  const ids = cartRaw.map((_) => _.item.product_id);
  const [
    request,
    { isLoading: isLoadingProducts }
  ] = useLazyGetProductsByIdsQuery(); // const cartProducts = useAppSelector(cartProductsSelectors.products);

  const requestProductAndGetBuildedCart = ({
    fullName,
    phone,
    email,
    buyer_comment
  }: T) => {
    request(ids)
      .then((res) => res.data)
      .then(r => {
        if (r?.data !== undefined) {
          buildCart(r.data, {
            fullName,
            phone,
            email,
            buyer_comment
          })
        }
      });
  }

  const buildCart = (productList: TypeProduct[], {
    fullName,
    phone,
    email,
    buyer_comment
  }: T) => {
    if (productList) {
      const products: any = [];

      cartRaw.forEach((cartItem) => {
        const product = getProductByProdIdInCart(productList, cartItem);

        if (product) {
          let sku = product?.sku;
          let comment =
            (!sku || sku.trim().length === 0 ? 'Відсутній артикул / ' : '') +
            product.name;

          if (cartItem.item?.offer_id) {
            const offer = getOfferByProdIdInCart(product, cartItem);
            sku = offer?.sku;
            comment =
              (!sku || sku.trim().length === 0 ? 'Відсутній артикул / ' : '') +
              product.name +
              ': ' +
              getObjListOfferProps({ offer })?.join('/');
          }

          const copy = {
            sku: sku,
            price: product.min_price,
            quantity: cartItem.qty,
            comment: comment,
          };

          products.push(copy);
        }
      });

      const _buildedCart = {
        products,
        buyer: {
          full_name: fullName,
          phone,
          email,
        },
        buyer_comment,
      };

      setBuildedCart(_buildedCart)
    }
  }

  return {
    requestProductAndGetBuildedCart,
    buildedCart,
    isLoadingProducts
  };
};


function getObjListOfferProps({ offer }: { offer: TypeOffer | undefined }) {
  return offer?.properties.map((prop) => prop.name + ' ' + prop.value);
}
