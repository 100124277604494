import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { CartPageMainWrapper } from '../../CartPageDfOriginal/widgets/CartPageMainWrapper';

export function CollapsedProducts() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='accordion-cart'
        id='accordion-cart'
      >
        <Typography variant='h6'>Товари</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CartPageMainWrapper />
      </AccordionDetails>
    </Accordion>
  );
}
