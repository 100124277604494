import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = import.meta.env.VITE_API_KEYCRM_BASE_URL;
const KeyCRM_GET_ORDER_STATUS = '/get_order_info.php';
const ENDPOINT__KEYCRM_CREATE_ORDER = '/create_order.php';

export const RTKQKeyCrmApi = createApi({
  reducerPath: 'api/keycrm',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getKeyCrmOrderStatus: builder.query<
      any /* resp type */,
      any /* req type */
    >({
      keepUnusedDataFor: 5,
      query: (id) => {
        return KeyCRM_GET_ORDER_STATUS + `?order_id=${id}`;
      },
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const resp = result.data;

          console.log(resp);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    keyCrmCreateOrder: builder.mutation<
      any | undefined, // ResponseData, // return data type
      // 2do: id повинно бути число
      { keyCrmCartObject: any; } // RequestData // post params
    >({
      query: ({ keyCrmCartObject }) => {
        return {
          url: ENDPOINT__KEYCRM_CREATE_ORDER,
          method: 'POST',
          body: JSON.stringify({
            order: keyCrmCartObject,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      // async onQueryStarted(objReq, { queryFulfilled }) {
      //   try {
      //     const result = await queryFulfilled;
      //     const response = await result.data;

      //     showNotification(response, objReq);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // },
    }),
  }),
});

export const {
  useLazyGetKeyCrmOrderStatusQuery,
  useKeyCrmCreateOrderMutation
} = RTKQKeyCrmApi;
