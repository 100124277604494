import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import { cartProductsSelectors } from '@/fsd/entities/cartProducts/model/selectors/cartProductsSelectors';
import { TypeProduct } from '@/fsd/shared/types';
import { getProductByProdIdInCart } from '@/fsd/shared/ui/Cart/cartUtils';

import { CartItemInfo } from '../widgets/CartItemInfo';
import { CartItemThumbnail } from '../widgets/CartItemThumbnail';
import s from './CartItem.module.scss';

export const CartItem = ({ cartItem }: { cartItem: CartItemType }) => {
  const cartProducts = useAppSelector(cartProductsSelectors.products);
  const product = getProductByProdIdInCart(cartProducts, cartItem);

  return (
    <div
      data-product-id={cartItem.item.product_id}
      data-offer-id={cartItem.item.offer_id}
    >
      {!product && _MsgProductNotFound(cartItem)}
      {product && _ContinueRender(product, cartItem)}
    </div>
  );
};

// -----------------------------------------
// ----------  Private functions  ----------
// -----------------------------------------

function _ContinueRender(product: TypeProduct, cartProd: CartItemType) {
  return (
    <div className={s['CartItem']}>
      <CartItemThumbnail product={product} />
      <CartItemInfo product={product} cartItem={cartProd} />
    </div>
  );
}

function _MsgProductNotFound(cartProd: CartItemType) {
  return (
    <div className={s['CartItem']} style={{ display: 'block' }}>
      Товар на сервері не знайдено. Код {cartProd.item.product_id} (код варіанту{' '}
      {cartProd.item.offer_id}). Очевидно такий товар раніше був на сервері, але
      зараз немає.
    </div>
  );
}
