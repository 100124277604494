import { useTitle } from '@/hooks';

import { SendCartToKeyCrmAndAutoOpenMonoCheckout } from '../../utils/SendCartToKeyCrmAndAutoOpenMonoCheckout';

export function WrapAutoOpenMono() {
  useTitle('Оформлення замовлення');

  return (
    <div className='ContentWrapper ContentWrapper-Vertical'>
      <h1 style={{ textAlign: 'center' }}>Оформлення замовлення</h1>
      <SendCartToKeyCrmAndAutoOpenMonoCheckout />
    </div>
  );
}
