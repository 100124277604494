// import { createHashRouter } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';

import App from '@/fsd/app/App';
import { CartPageCustom } from '@/fsd/pages/Cart/CartPageCustom';
import { CartPageDfOriginalAsync } from '@/fsd/pages/Cart/CartPageDfOriginal';
import { CheckoutPage } from '@/fsd/pages/Cart/CheckoutPage/CheckoutPage';
import { CheckoutPageAutoOpenMono } from '@/fsd/pages/Cart/CheckoutPageAutoOpenMono/CheckoutPageAutoOpenMono';
import { AboutAsync } from '@/fsd/pages/information/About/ui';
import { ContactsPageWrapper } from '@/fsd/pages/information/ContactsPage';
import { CooperationAsync } from '@/fsd/pages/information/Cooperation';
import { DeliveryAndPaymentAsync } from '@/fsd/pages/information/DeliveryAndPayment';
import { OfertaAsync } from '@/fsd/pages/information/Oferta/ui';
import { KeyCRMDataPage } from '@/fsd/pages/KeyCRMDataPage';
import { OrderPaymentAsync } from '@/fsd/pages/order/OrderPayment/ui';
import { OrderPaymentMonoAsync } from '@/fsd/pages/order/OrderPaymentMono';
import { OrderPaymentMonoAutoOpenMonoPlataAsync } from '@/fsd/pages/order/OrderPaymentMonoAutoOpenMonoPlata';
import { ThankYouBasicAsync } from '@/fsd/pages/order/ThankYouBasic/ui/ThankYouBasic.async';
import { ThankYouCheckMonoResponseAsync } from '@/fsd/pages/order/ThankYouCheckMonoResponse/ui/ThankYouCheckMonoResponse.async';
import { ProductPage } from '@/fsd/pages/ProductPage';
import { ProductsPage } from '@/fsd/pages/ProductsPage';
import { ErrorElement } from './ErrorElement';

import { RoutesNames } from './RoutesName';

export const BuildRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorElement message='App' />,
    children: [
      {
        index: true,
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/discounts',
        element: <ErrorElement message='Такої сторінки не існує' />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: RoutesNames.OrderGroup.Checkout,
        element: <CheckoutPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: RoutesNames.OrderGroup.CheckoutAutoOpenMono,
        element: <CheckoutPageAutoOpenMono />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: RoutesNames.ProductPage + ':id',
        element: <ProductPage />,
        errorElement: <ErrorElement message='ProductPage' />,
      },
      // {
      //     path: 'product_by_react_router/:id',
      //     element: <ProductPage />,
      //     loader: async ({ request, params }) => {
      //         // loaders can be async functions
      //         const res = await fetch(
      //             '/backend/db/keyCRM/products.json',
      //             {
      //                 signal: request.signal,
      //             }
      //         );
      //         const resJson = await res.json();
      //         const products = fromJSON(resJson.data);
      //         const product = products.find((i: TypeProduct) =>
      //             params.id ? i.id === +params.id : undefined
      //         );

      //         return product ? product : null; // loader should return object or null
      //     },
      //     errorElement: <ErrorElement message='ProductPage' />,
      // },
      {
        path: RoutesNames.KeyCRM,
        element: <KeyCRMDataPage />,
        errorElement: <ErrorElement message='KeyCRMData' />,
      },
      {
        path: RoutesNames.Cart,
        element: <CartPageDfOriginalAsync />,
        errorElement: <ErrorElement message='Cart Page' />,
      },

      {
        path: RoutesNames.OrderGroup.OrderAutoOpenMonoPlata + '/' + ':orderId',
        element: <OrderPaymentMonoAutoOpenMonoPlataAsync />,
        errorElement: (
          <ErrorElement message='Сторінка OrderAutoOpenMonoPlata' />
        ),
      },
      {
        path: RoutesNames.OrderGroup.Order + '/' + ':orderId',
        element: <OrderPaymentMonoAsync />,
        errorElement: (
          <ErrorElement message='Сторінка підтвердження створення замовлення в CRM завантажена з помилкою' />
        ),
        // children: [
        //   {
        //     path: RoutesNames.OrderGroup.Payment,
        //     element: <OrderPaymentMonoAsync />,
        //     errorElement: (
        //       <ErrorElement message='Сторінка вибору оплати завантажена з помилкою' />
        //     ),
        //   },
        //   {
        //     path: RoutesNames.OrderGroup.ThankYou,
        //     element: <ThankYouAsync />,
        //     errorElement: (
        //       <ErrorElement message='Сторінка "Дякуємо за замовлення" завантажена з помилкою' />
        //     ),
        //   },
        // ],
      },
      {
        path: RoutesNames.OrderGroup.Order + '/:orderId/' + RoutesNames.OrderGroup.Payment, // '/order/:orderId/payment',
        // element: <OrderPaymentMonoAsync />,
        element: <OrderPaymentAsync />,
        errorElement: (
          <ErrorElement message='Сторінка вибору оплати завантажена з помилкою' />
        ),
      },
      {
        path: RoutesNames.OrderGroup.Order + '/:orderId/' + RoutesNames.OrderGroup.ThankYouBasic, // http://localhost:5173/order/4250/thank-you
        element: <ThankYouBasicAsync />,
        errorElement: (
          <ErrorElement message='Сторінка "Дякуємо за замовлення" завантажена з помилкою' />
        ),
      },
      {
        path: RoutesNames.OrderGroup.Order + '/:orderId/' + RoutesNames.OrderGroup.ThankYouCheckMonoResponse, // http://localhost:5173/order/4250/thank-you/check-mono-response
        element: <ThankYouCheckMonoResponseAsync />,
        errorElement: (
          <ErrorElement message='Сторінка "Дякуємо за замовлення" завантажена з помилкою' />
        ),
      },
      {
        path: '/cart-old',
        element: <CartPageCustom />,
        errorElement: <ErrorElement message='Cart Page Custom' />,
      },
      {
        path: RoutesNames.Info.Contacts,
        element: <ContactsPageWrapper />,
        errorElement: <ErrorElement message='Contacts Page' />,
      },
      {
        path: RoutesNames.Info.Oferta,
        element: <OfertaAsync />,
        errorElement: <ErrorElement message='Offer Page' />,
      },
      {
        path: RoutesNames.Info.About,
        element: <AboutAsync />,
        errorElement: <ErrorElement message='About Page' />,
      },
      {
        path: RoutesNames.Info.DeliveryAndPayment,
        element: <DeliveryAndPaymentAsync />,
        errorElement: <ErrorElement message='Delivery And Payment Page' />,
      },
      {
        path: RoutesNames.Info.Cooperation,
        element: <CooperationAsync />,
        errorElement: <ErrorElement message='Cooperation Page' />,
      },
    ],
  },
]);
