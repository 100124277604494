
const USER = 'user';

export enum DeliveryEnum {
  NovaPoshta = 'nova poshta',
  UkrPosta = 'ukr poshta',
  SelfPicking = 'self picking',
}

export enum PaymentEnum {
  LiqPayWidget = 'liqpay_widget',
  MonoCheckout = 'mono_checkout',
  PaymentOnPicking = 'payment_on_picking',
  FOPBezdushna = 'fop_bezdushna',
}

export interface UserBuyerI {
  full_name: string,
  phone: string,
  email: string,
}

export interface UserDataI {
  // full_name: string,
  // phone: string,
  // email: string,
  buyer: UserBuyerI,
  buyer_comment: string,
  delivery_method: DeliveryEnum,
  ukr_poshta_address: string,
  payment_type: PaymentEnum
}

const EmptyUser: UserDataI = {
  // full_name: '',
  // phone: '',
  // email: '',
  buyer: {
    full_name: '',
    phone: '',
    email: '',
  },
  buyer_comment: '',
  delivery_method: DeliveryEnum.NovaPoshta,
  ukr_poshta_address: '',
  payment_type: PaymentEnum.PaymentOnPicking
}

export class StoredUserData {
  static getUser(): UserDataI {
    let result = EmptyUser
    
    const str = localStorage.getItem(USER)
    if (str !== null) {
      try {
        const obj = JSON.parse(str) as any
        const normObj = this.#normalizeUserObject(obj)
        result = normObj
      } catch (err) {
        console.error(err)
      }
    } else {
      result = EmptyUser
    }

    return result;
  }

  static #normalizeUserObject(obj: any) {
    let normalizeObj: any = {};

    normalizeObj.buyer = {}
    normalizeObj.buyer.full_name = obj?.buyer.full_name || ''
    normalizeObj.buyer.phone = obj?.buyer.phone || ''
    normalizeObj.buyer.email = obj?.buyer.email || ''
    
    normalizeObj.buyer_comment = obj?.buyer_comment || ''
    normalizeObj.delivery_method = obj?.delivery_method || DeliveryEnum.NovaPoshta
    normalizeObj.ukr_poshta_address = obj?.ukr_poshta_address || ''
    normalizeObj.payment_type = obj?.payment_type || PaymentEnum.PaymentOnPicking

    const normObjType: UserDataI = normalizeObj as UserDataI;

    return normObjType;
  }

  static saveUser({ userData }: { userData: Partial<UserDataI> }) {
    const user = { ...this.getUser(), ...userData }
    localStorage.setItem(USER, JSON.stringify(user));
  }

  static getDeliveryMethod(): string {
    return this.getUser().delivery_method
  }
}
