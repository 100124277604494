import './CartItemDeleteButton.scss';

import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions } from '@/fsd/entities/cart';
import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';

export const CartItemButtonDelete = ({
  cartItem,
}: {
  cartItem: CartItemType;
}) => {
  const dispatch = useAppDispatch();

  const item = {
    product_id: cartItem.item.product_id,
    offer_id: cartItem.item.offer_id,
    color: cartItem.item.color,
  };

  const onDelete = () => {
    dispatch(cartSliceActions.deleteProduct({ ...item }));
  };

  return (
    <div className='CartItem-Buttons'>
      <div
        className='CartItem-RemoveButton btn_text'
        aria-label='delete'
        onClick={onDelete}
      >
        Видалити
      </div>
    </div>
  );
};
