
export const RoutesNames = {
  Info: {
    DeliveryAndPayment: '/delivery-and-payment',
    Cooperation: '/cooperation',
    Contacts: '/contacts',
    Oferta: '/oferta',
    About: '/about',
  },
  KeyCRM: '/keycrm',
  ProductPage: '/product/',
  Cart: '/cart',
  OrderGroup: {
    OrderAutoOpenMonoPlata: '/order-auto-open-mono-plata',
    Order: '/order',
    Checkout: '/checkout',
    CheckoutAutoOpenMono: '/checkout-auto-open-mono',
    Payment: 'payment', //test mono
    ThankYouBasic: 'thank-you', // 2do: додати слеш як в інших роутах
    ThankYouCheckMonoResponse: 'thank-you/check-mono-response', // 2do: додати слеш як в інших роутах
  },
};
