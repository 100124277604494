import * as React from 'react';

import { AgeItemTypeMenu } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ListItemText } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import { useClickMenuItem } from '../../../../../hooks/useClickMenuItem';

export function MuiList(props: { item: any, setHamburger: (isOpen: boolean) => void }) {
  const { item, setHamburger } = props;

  if (item.child.length > 0) {
    return <ButtonWithChild item={item} setHamburger={setHamburger} />;
  }

  return <ButtonLink item={item} setHamburger={setHamburger}/>;
}

function ButtonWithChild(props: { item: any, setHamburger: (isOpen: boolean) => void  }) {
  const { item, setHamburger } = props;
  const [open, setOpen] = React.useState(false);
  const { onClickMenuItem } = useClickMenuItem();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component='nav'
      aria-labelledby='nested-list-subheader'
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={item.name}
          onClick={() => {
            onClickMenuItem(item);
            setHamburger(false);
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <div style={{ paddingLeft: 16 }}>
              {item.child.map((_c: any, idx: React.Key) => (
                <MuiList item={_c} key={idx} setHamburger={setHamburger} />
              ))}
            </div>
          </List>
        </Collapse>
      }
    </List>
  );
}

function ButtonLink(props: { item: any, setHamburger: (isOpen: boolean) => void  }) {
  const { item, setHamburger } = props;
  const { onClickMenuItem } = useClickMenuItem();

  return (
    <ListItemButton component='a' href={(item as AgeItemTypeMenu).link}>
      <ListItemText primary={item.name} onClick={() => {
        onClickMenuItem(item);
        setHamburger(false);
      }} />
    </ListItemButton>
  );
}
