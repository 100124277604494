import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const ENDPOINT__LIQPAY_RAW_DATA_BY_POST = '/payments/get_data_signature_by_post.php';
const ENDPOINT__LIQPAY_RAW_DATA = '/payments/get_data_signature.php';
// const ENDPOINT__MONOBABNK_ORDER_STATUS = '/mono_status_by_order_ref.php';

export const RTKQLiqPayCheckout = createApi({
  reducerPath: 'api/liqpay',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    liqPayGetPaymentData: builder.mutation<
      any | undefined, // ResponseData, // return data type
      // 2do: id повинно бути число
      { order_id: any } // RequestData // post params
    >({
      query: ({ order_id }) => {
        const data = { order_id };

        return {
          url: ENDPOINT__LIQPAY_RAW_DATA_BY_POST,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'content-type': 'application/json',
          },
        };
      },
      async onQueryStarted(_objReq, { queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const response = await result.data;

          console.log(response);

          // showNotification(response, objReq);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    liqPayGetPaymentDataByGet: builder.query<any /* resp type */, { order_id: any } /* req type */>(
      {
        keepUnusedDataFor: 0,
        query: ({ order_id }) => {
          return ENDPOINT__LIQPAY_RAW_DATA + `?order_id=${order_id}`;
        },
        async onQueryStarted(_id, { queryFulfilled }) {
          try {
            const result = await queryFulfilled;
            const resp = result.data;

            console.log(resp);
          } catch (err) {
            console.log(err);
          }
        },
      }
    ),
  }),
});

export const { 
  useLiqPayGetPaymentDataMutation,   
  useLazyLiqPayGetPaymentDataByGetQuery 
} =
  RTKQLiqPayCheckout;
