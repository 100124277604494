import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

import { useKeyCrmCreateOrderMutation } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQKeyCrmApi';
import { useLiqPayGetPaymentDataMutation } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQLiqPayCheckout';
import { useMonoCreateOrderMutation } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQMonobankCheckout';
import { LiqPayCheckoutCallback } from '@/fsd/pages/KeyCRMDataPage/ui/LiqPayCheckoutCallback';
import { setDataSignToLiqPayFormRedirect } from '@/fsd/pages/KeyCRMDataPage/ui/setDataSignToLiqPayFormRedirect';
import { useLazyBuildKeyWithNewCredentials } from '@/fsd/shared/ui/Cart/useLazyBuildKeyWithNewCredentials';
import { PaymentEnum, StoredUserData } from '@/fsd/shared/utils/StoredUserData';
import { RoutesNames } from '@/routes/RoutesName';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';

export function CreateOrderBtn() {
  const [isShowSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [isTryCreateKeyCrmOrder, setTryCreateKeyCrmOrder] = useState<boolean | undefined>(false);
  const navigate = useNavigate();
  const [keyCrmCreateOrder] = useKeyCrmCreateOrderMutation();
  const [request] = useLiqPayGetPaymentDataMutation();
  const [monoCreateOrder] = useMonoCreateOrderMutation();

  const {
    requestProductAndGetBuildedCart,
    buildedCart,
    isLoadingProducts
  } = useLazyBuildKeyWithNewCredentials()

  useEffect(() => {
    if (buildedCart) {
      createKeyCrmOrderOnServer()
    }
  }, [buildedCart])

  const handleCreateOrder = () => {
    const user = StoredUserData.getUser();

    // Unblock Continune buttons
    if (user.buyer.full_name.trim().length > 1 && user.buyer.phone.trim().length > 1) {
      setShowSnackbar(false);

      let email = user.buyer.email
      if (email.length < 6) {
        email = 'danceex_buyer@gmail.com'
      }

      requestProductAndGetBuildedCart({
        fullName: user.buyer.full_name,
        phone: user.buyer.phone,
        email,
        buyer_comment: user.buyer_comment,
      })
    } else {
      setShowSnackbar(true);
    }
    // Unblock Continue buttons
  }

  const closeSnakbar = () => {
    setShowSnackbar(false);
  };

  const createKeyCrmOrderOnServer = () => {

    // Fix create 2 orders
    if (isTryCreateKeyCrmOrder === false) {
      setTryCreateKeyCrmOrder(true);

      keyCrmCreateOrder({ keyCrmCartObject: buildedCart })
        .then((res) => res.data)
        .then((_keyCrmResponse) => {
          if (_keyCrmResponse !== undefined) {
            const user = StoredUserData.getUser();

            if (user.payment_type !== PaymentEnum.LiqPayWidget && user.payment_type !== PaymentEnum.MonoCheckout) {
              navigate(RoutesNames.OrderGroup.Order + '/' + _keyCrmResponse.id + '/' + RoutesNames.OrderGroup.ThankYouBasic, {
                replace: true,
                state: { keyCrmOrderInfo: _keyCrmResponse },
              });
            }

            if (user.payment_type === PaymentEnum.LiqPayWidget) {
              request({ order_id: _keyCrmResponse.id })
                .then((res) => {
                  console.log(res.data);
                  // eslint-disable-line
                  LiqPayCheckoutCallback({
                    liqpay_form_raw: res.data?.liqpay_form_raw, liqpayCallback: (data) => {
                      console.log('liqpayCallback', data);

                      navigate(RoutesNames.OrderGroup.Order + '/' + _keyCrmResponse.id + '/' + RoutesNames.OrderGroup.ThankYouBasic, {
                        replace: true,
                        state: { keyCrmOrderInfo: _keyCrmResponse },
                      });

                    }
                  });
                  setDataSignToLiqPayFormRedirect({ liqpay_form_raw: res.data?.liqpay_form_raw });
                })
            }

            if (user.payment_type === PaymentEnum.MonoCheckout) {

              const orderInfo = _keyCrmResponse;

              handleClickMock()

              function handleClickMock() {
                let sumQty = 0;
                orderInfo.products.forEach((p: any) => {
                  sumQty += p.quantity;
                });

                const monoProds = orderInfo.products.map((p: any) => {
                  return {
                    name: p.name,
                    cnt: p.quantity,
                    price: p.price_sold,
                    code_product: p.id,
                    product_img_src: p.picture?.thumbnail || '',
                  };
                });

                const checkoutObj = {
                  order_ref: orderInfo.id,
                  amount: orderInfo.grand_total,
                  count: sumQty,
                  products: monoProds,
                };

                monoCreateOrder({ checkoutObj: checkoutObj })
                  .then((res) => {
                    console.log(res.data);
                    return res.data;
                  })
                  .then((r) => {
                    if ('' !== r) {
                      const mono = r;
                      console.log(mono);

                      if ('errCode' in mono) {
                        console.log('errCode in mono');
                        // setMonoResp(mono.errText);
                      }
                      if (mono?.errCode) {
                        // setMonoResp(mono.errText);
                      }

                      if (mono?.result?.redirect_url) {
                        console.log(mono.order_id);
                        window.location.href = mono.result.redirect_url;
                      }
                    }
                  })
                  .finally(() => { });
              }
            }
          } else {
            const msg = 'Не вдалось надіслати замовлення. Перевірте інтернет та спробуйте знову. Або будь ласка напишіть повідомлення у чат менеджеру'
            toast.info(msg, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              transition: Bounce,
            });
          }
        })
        .catch((_) => console.error(_))
        .finally(() => setTryCreateKeyCrmOrder(false))
    }
  };

  const Loaders = () => (
    <>
      {isLoadingProducts && <div>Завантажаження списку товарів <CircularProgress color='success' /></div>}
      {isTryCreateKeyCrmOrder && <div>Створення замовлення на сервері <CircularProgress color='info' /></div>}
    </>
  )

  return (
    <>
      <Button
        variant='contained'
        style={{ marginTop: '12px' }}
        onClick={handleCreateOrder}
      >
        Продовжити
      </Button>
      <Loaders />
      <Snackbar open={isShowSnackbar} autoHideDuration={4000} onClose={closeSnakbar}>
        <Alert
          onClose={closeSnakbar}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Заповніть контактні дані
        </Alert>
      </Snackbar>
    </>
  );
}

