import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = import.meta.env.VITE_API_DANCEEX_BASE_URL;

const ENDPOINT__MONOBABNK_CHECKOUT = '/mono_create_checkout.php';
const ENDPOINT__MONOBABNK_ORDER_STATUS = '/mono_status_by_order_ref.php';

export const RTKQMonobankCheckout = createApi({
  reducerPath: 'api/monobank',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    monoCreateOrder: builder.mutation<
      any | undefined, // ResponseData, // return data type
      // 2do: id повинно бути число
      { checkoutObj: any } // RequestData // post params
    >({
      query: ({ checkoutObj }) => {
        const data = { monoCartObj: checkoutObj };

        return {
          url: ENDPOINT__MONOBABNK_CHECKOUT,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'content-type': 'application/json',
          },
        };
      },
      async onQueryStarted(_objReq, { queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const response = await result.data;

          console.log(response);

          // showNotification(response, objReq);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getStatusByOrderRef: builder.query<any /* resp type */, any /* req type */>(
      {
        keepUnusedDataFor: 0,
        query: (id) => {
          return ENDPOINT__MONOBABNK_ORDER_STATUS + `?order_ref=${id}`;
        },
        async onQueryStarted(_id, { queryFulfilled }) {
          try {
            const result = await queryFulfilled;
            const resp = result.data;

            console.log(resp);
          } catch (err) {
            console.log(err);
          }
        },
      }
    ),
  }),
});

export const { useMonoCreateOrderMutation, useLazyGetStatusByOrderRefQuery } =
  RTKQMonobankCheckout;
